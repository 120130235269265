import platformImg2 from "../images/test2.png";
import platformImg3 from "../images/test3.png";
import platformImg4 from "../images/test4.png";
import platformImg5 from "../images/test5.png";
import platformImg6 from "../images/test6.png";
import mazoonit from "../images/mazoonit.png";
import backgroundImg from "../images/windows.png";
import spriteRunLeft from "../images/spriteRunLeft-min.png";
import spriteRunRight from "../images/spriteRunRight-min.png";
import spriteStandLeft from "../images/spriteStandLeft-min.png";
import spriteStandRight from "../images/spriteStandRight-min.png";
import "babel-polyfill";

import Player from "./player.js";
import { Background, BackgroundObject, Platform } from "./objects";
import { createImage, constants } from "./utils";
var counter = 0;

function preloadImages(urls) {
  const promises = Object.keys(urls).map((key) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = urls[key].src;
      image.onload = () => resolve(image);
      image.onerror = () => reject(`Image failed to load: ${key}`);
    });
  });
  return Promise.all(promises);
}

export default async function init({ canvasContext, canvas }) {
  let urls = {
    spriteRunLeft: { src: spriteRunLeft },
    spriteRunRight: { src: spriteRunRight },
    spriteStandLeft: { src: spriteStandLeft },
    spriteStandRight: { src: spriteStandRight },
    backgroundImg: { src: backgroundImg },
    mazoonit: { src: mazoonit },
    platformImg2: { src: platformImg2 },
    platformImg3: { src: platformImg3 },
    platformImg4: { src: platformImg4 },
    platformImg5: { src: platformImg5 },
    platformImg6: { src: platformImg6 },
  };
  let images = await preloadImages(urls);
  let platforms = [
    new Platform({
      x: 200,
      y:150,
      image: createImage(mazoonit),
      canvasContext: canvasContext,
    }),
    new Platform({
      x: 1000,
      y: 120,
      image: createImage(platformImg6),
      canvasContext: canvasContext,
    }),
    new Platform({
      x: 1800,
      y: 140,
      image: createImage(platformImg2),
      canvasContext: canvasContext,
    }),
    new Platform({
      x: 2600,
      y: 120,
      image: createImage(platformImg3),
      canvasContext: canvasContext,
    }),
    new Platform({
      x: 3400,
      y: 130,
      image: createImage(platformImg4),
      canvasContext: canvasContext,
    }),
    new Platform({
      x: 4200,
      y: 140,
      image: createImage(platformImg5),
      canvasContext: canvasContext,
    }),
  ];

  const background = new Background({
    x: -1,
    y: -1,
    image: createImage(backgroundImg),
    images: [
      {
        x: -1,
        y: -1,
        image: createImage(backgroundImg),
        from: 0,
        to: 20000,
        posX: window.innerWidth,
        posY: window.innerHeight,
      },
    ],
    canvasContext: canvasContext,
  });

  const player = new Player({
    canvas: canvas,
    canvasContext: canvasContext,
    frameRate: 1,
  });

  return { player, background, platforms };
}

import "../../src/index.css";
import "../../src/loading.css";
import "babel-polyfill";
import init from "./init";

const canvas = document.querySelector("canvas");
const c = canvas.getContext("2d");
canvas.width = window.innerWidth;
canvas.height = window.innerHeight;
let playerSpeed = 10;
let platformSpeed = 10;
if (canvas.height > canvas.width) {
  playerSpeed = 25;
  platformSpeed = 25;
}
init({
  canvasContext: c,
  canvas: canvas,
})
  .then(({ player, background, platforms }) => {
    let loadingElement = document.getElementById("loading");
    loadingElement.style.opacity = 0;
    loadingElement.style.display = "none";

    let lastKey;
    let scrollOffset = 0;

    const keys = {
      right: {
        pressed: false,
      },
      left: {
        pressed: false,
      },
    };

    function animate() {
      requestAnimationFrame(animate);
      c.clearRect(0, 0, canvas.width, canvas.height);

      background.draw(scrollOffset);

      platforms.forEach((platform) => {
        platform.draw();
      });

      player.update(c);
      //handle moving
      if (keys.right.pressed && player.position.x < 600) {
        player.velocity.x = playerSpeed;
      } else if (keys.left.pressed && player.position.x > 100) {
        player.velocity.x = -playerSpeed;
      } else {
        console.log(scrollOffset)
           //platform moving
          player.velocity.x = 0;
          if (keys.right.pressed && scrollOffset<24000) {
            platforms.forEach((platform) => {
              platform.position.x -= playerSpeed;
              scrollOffset += platformSpeed;
            });
          } else if (keys.left.pressed && scrollOffset > 0) {
            platforms.forEach((platform) => {
              platform.position.x += playerSpeed;
              scrollOffset -= platformSpeed;
            });
          }
        
      }

      //collision detection
      platforms.forEach((platform) => {
        if (
          player.position.y + player.height <= platform.position.y &&
          player.velocity.y + player.height + player.position.y >=
            platform.position.y &&
          player.width + player.position.x >= platform.position.x &&
          player.position.x <= platform.position.x + platform.width
        ) {
          if (platform.position.y < 150) {
            platform.position.y += 10;
          }
          player.velocity.y = 0;
        } else {
          platform.color = "blue";
        }
      });

      if (
        keys.right.pressed &&
        lastKey == "right" &&
        player.currentSprite !== player.sprites.runRight
      ) {
        player.frame = 1;
        player.currentSprite = player.sprites.runRight;
      } else if (
        keys.left.pressed &&
        lastKey == "left" &&
        player.currentSprite !== player.sprites.runLeft
      ) {
        player.frame = 1;
        player.currentSprite = player.sprites.runLeft;
      } else if (
        !keys.left.pressed &&
        lastKey == "left" &&
        player.currentSprite !== player.sprites.runLeft
      ) {
        player.frame = 1;
        player.currentSprite = player.sprites.standLeft;
      } else if (
        !keys.right.pressed &&
        lastKey == "right" &&
        player.currentSprite !== player.sprites.runLeft
      ) {
        player.frame = 1;
        player.currentSprite = player.sprites.standRight;
      }

      if (scrollOffset > 2000) {
        console.log("winn");
      }
    }

    animate();

    addEventListener("keydown", (e) => {
      let { keyCode } = e;
      switch (keyCode) {
        case 32:
          player.velocity.y = -16;
          break;
        case 37: //left
          lastKey = "left";
          keys.left.pressed = true;
          if (left > 10) {
            left -= 10;
            swiper.style.left = left + "px";
          }
          break;
        case 38: //up
          player.velocity.y = -16;
          break;
        case 39: //right
          lastKey = "right";
          if (left < 100) {
            left += 10;
            swiper.style.left = left + "px";
          }
          keys.right.pressed = true;
          break;
      }
    });

    addEventListener("keyup", (e) => {
      let { keyCode } = e;
      switch (keyCode) {
        case 32:
          player.velocity.y -= 20;
          break;
        case 37: //left
          player.currentSprite = player.sprites.standLeft;
          keys.left.pressed = false;
          swiper.style.left = 55 + "px";
          left = 55;
          break;
        case 38: //up
          player.velocity.y -= 20;
          break;
        case 39: //right
          swiper.style.left = 55 + "px";
          left = 55;
          keys.right.pressed = false;
          break;
      }
    });

    let aboutMeButton = document.getElementById("aboutMeButton");
    let aboutClose = document.getElementById("aboutClose");
    let flag = true;

    aboutMeButton.addEventListener(
      "click",
      function (e) {
        let content = document.getElementById("static");
        if (!flag) {
          content.style.left = "-1500px";
          flag = true;
        } else {
          content.style.left = "0px";
          flag = false;
        }
      },
      false
    );
    aboutClose.addEventListener(
      "click",
      function (e) {
        let content = document.getElementById("static");
        content.style.left = "-1500px";
        flag = true;
      },
      false
    );

    let touchstartX = 0;
    let touchendX = 0;
    let touchstartY = 0;
    let touchendY = 0;
    let left = 55;
    let top = 0;
    function checkDirection() {
      swiper.style.left = 55 + "px";
      left = 55;
      if (touchendY > touchstartY) {
        player.velocity.y = -16;
      }
    }

    document.addEventListener("touchstart", (e) => {
      touchstartX = e.changedTouches[0].screenX;
      touchstartY = e.changedTouches[0].screenY;
    });
    document.addEventListener("touchmove", (e) => {
      let lastX = touchstartX;
      let lastY = touchstartY;

      touchstartX = e.changedTouches[0].screenX;
      touchstartY = e.changedTouches[0].screenY;
      let swiper = document.getElementById("swiper");
      if (lastX < touchstartX) {
        if (left < 100 && lastX < touchstartX + 100) {
          left += 10;
          swiper.style.left = left + "px";
        }
        if (left > 30) {
          lastKey = "right";
          keys.right.pressed = true;
          keys.left.pressed = false;
        }
      } else {
        if (left > 10 && touchstartX < lastX + 100) {
          left -= 10;
          swiper.style.left = left + "px";
        }
        if (left < 30) {
          lastKey = "left";
          keys.right.pressed = false;
          keys.left.pressed = true;
        }
      }
      /*
      if (lastY < touchstartY) {
        if (top < window.innerHeight - 100) {
          top += 5;
          swiper.style.top = top + "px";
        }
      } else {
        if (top > 10) {
          top -= 5;
          swiper.style.top = top + "px";
        }
      }
      */
      console.log("move");
      console.log(touchstartX);
    });
    document.addEventListener("touchend", (e) => {
      touchendX = e.changedTouches[0].screenX;
      touchendY = e.changedTouches[0].screenY;
      keys.right.pressed = false;
      keys.left.pressed = false;
      player.currentSprite = player.sprites.standLeft;
      checkDirection();
    });
  })
  .catch((e) => {
    console.log(e);
    console.log("what the fuck");
  });
